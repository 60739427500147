@media (max-width: 860px) {
    .imageDiv{
        display: none;
    }
    .formParentDiv{
        width: 100%;
    }
    .formChildDiv{
        width: 100%;
        padding: 30px;
    }
    .loginMainDiv{
        width: 80%;
    }
}

@media (max-width: 1103px) {
    .heading{
        font-size: 28px;
    }
}

@media (max-width: 413px) {
    .logORsign{
        font-size: 11.3px;
    }
    .heading{
        font-size: 25px;
    }
    .smalHead{
        font-size: 16px;
    }
}

