@media (max-width: 860px) {
    .imageDiv{
        display: none;
    }
    .formParentDiv{
        width: 100%;
    }
    .formChildDiv{
        width: 100%;
        padding: 30px;
    }
    .loginMainDiv{
        width: 80%;
        height: 97%;
        border-radius: 35px;
        transition: 1s;
    }
}
@media (max-width: 560px) {
   
    .loginMainDiv{
        width: 80%;
        height: 67%;
        border-radius: 35px;
        transition: 1s;
        min-height: 550px;
    }
}

